import { Dialog as HUIDialog, DialogPanel, DialogTitle } from '@headlessui/react';
import cx from 'classnames';

import { Icon } from './Icon';

interface DialogProps extends Omit<React.ComponentPropsWithoutRef<'div'>, 'role'> {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
}

interface DialogHeaderProps {
  children: React.ReactNode;
  onClose?: () => void;
  showCloseButton?: boolean;
}

interface DialogContentProps {
  children: React.ReactNode;
}

interface DialogFooterProps {
  children: React.ReactNode;
  className?: string;
}

export function Dialog({ isOpen, onClose, children, ...rest }: DialogProps) {
  return (
    <HUIDialog open={isOpen} onClose={onClose} className="relative z-40" {...rest}>
      <div className="fixed inset-0 bg-black/30" aria-hidden="true" />

      <div className="fixed inset-0 flex items-center justify-center">
        <DialogPanel className="mx-auto w-[500px] rounded-[16px] bg-white">
          <div className="flex h-full flex-col">{children}</div>
        </DialogPanel>
      </div>
    </HUIDialog>
  );
}

export function DialogHeader({ children, onClose, showCloseButton = true }: DialogHeaderProps) {
  return (
    <div className="flex items-center justify-between px-4 pt-4">
      <DialogTitle className="text-l-bold">{children}</DialogTitle>
      {showCloseButton && onClose && (
        <button onClick={onClose} aria-label="Close dialog" type="button">
          <Icon name="close" className="text-[20px]" />
        </button>
      )}
    </div>
  );
}

export function DialogContent({ children }: DialogContentProps) {
  return <div className="flex-1 overflow-y-auto p-4">{children}</div>;
}

export function DialogFooter({ children, className }: DialogFooterProps) {
  return <div className={cx('flex gap-3 border-t border-warmgray-100 p-4', className)}>{children}</div>;
}
