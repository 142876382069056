import { createContext, useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import { useRevalidator } from 'react-router';
import type { Jsonify } from 'type-fest';

import type { ActionResponse as RefreshActionResponse } from '~/routes/auth.refresh';

import { ApiError } from '../../api/ApiError';
import { invariant } from '../../utils/invariant';

type DecoratedApiCall<Props, Result> = (apiCallProps: Props) => Promise<Result>;

type WithAuth = <Props extends { accessToken?: string }, Result>(
  apiFn: DecoratedApiCall<Props, Result>,
  options?: { logoutOn401?: boolean },
) => DecoratedApiCall<Omit<Props, 'accessToken'>, Result>;

const AuthContext = createContext<null | {
  withAuth: WithAuth;
}>(null);

interface AuthProviderProps {
  children: React.ReactNode;
  tokens: { accessToken?: string };
}

export function AuthProvider({ children, tokens }: AuthProviderProps) {
  const tokensRefreshPromiseRef = useRef<ReturnType<typeof refreshAuthTokens> | null>(null);

  const { accessToken } = tokens;

  // Cleans up refresh promise afterwards
  useEffect(() => {
    tokensRefreshPromiseRef.current = null;
  }, [tokens.accessToken]);

  const logout = useCallback(() => {
    window.location.assign('/auth/logout');
  }, []);

  const revalidator = useRevalidator();
  const refreshAuthTokensDeduped = useCallback(async () => {
    // Share refresh between API calls to prevent concurrent refresh calls
    if (tokensRefreshPromiseRef.current) {
      return tokensRefreshPromiseRef.current;
    }

    const promise = refreshAuthTokens();
    tokensRefreshPromiseRef.current = promise;
    const newTokens = await promise;

    // Ensure that the new access token is returned by the root loader
    // so that the AuthProvider retrieves a new one
    revalidator.revalidate();

    return newTokens;
  }, [revalidator]);

  /**
   * Handles authentication for a given API function
   * - returns a new function with access token in scope
   * - handles auto refresh of the access token
   */
  const withAuth: WithAuth = useMemo(() => {
    return (apiFn, { logoutOn401 = true } = {}) => {
      return async (variables) => {
        try {
          const result = await apiFn({ accessToken, ...variables } as Parameters<typeof apiFn>[0]);
          return result;
        } catch (err) {
          // Unknown error
          if (!(err instanceof ApiError)) {
            throw err;
          }

          // Determine the cause
          switch (err.status) {
            // When unauthorized, the access token may be expired
            case 401:
              // Try to refresh the access token
              try {
                const newTokens = await refreshAuthTokensDeduped();

                // Retry original api call with new access token
                return await apiFn({ accessToken: newTokens.accessToken, ...variables } as Parameters<typeof apiFn>[0]);
              } catch (err) {
                // Logout either when:
                // - refresh fails with 401, e.g. refresh token is expired (normal) or invalid (should not happen)
                // - retry fails with 401 after a successful refresh, which should not happen
                if (err instanceof ApiError && err.status === 401 && logoutOn401) {
                  logout();
                }

                // Let consumer handle errors
                throw err;
              }
            default:
              throw err;
          }
        }
      };
    };
  }, [accessToken, refreshAuthTokensDeduped, logout]);

  return (
    <AuthContext.Provider
      value={{
        withAuth,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  const context = useContext(AuthContext);

  invariant(context, 'Missing AuthProvider');

  return context;
}

// Retrieve new access token from a resource (api) route using a promise
// in order to deduplicate requests and retry failed api call inside the same promise
async function refreshAuthTokens() {
  // `refreshToken` should be sent implicitely with the session cookie
  const res = await fetch('/auth/refresh', { method: 'post' });

  if (!res.ok) {
    let json: unknown;
    try {
      json = await res.json();
    } catch (_err) {
      // handled below
    }
    throw ApiError.fromResponse(res, json);
  }

  const json = (await res.json()) as Jsonify<RefreshActionResponse>;

  // For typesafety only
  if ('error' in json) {
    throw ApiError.fromResponse(res, json);
  }

  return json;
}
