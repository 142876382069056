import cx from 'classnames';
import type { LinkProps as RouterLinkProps } from 'react-router';
import { Link as RouterLink } from 'react-router';

import type { ButtonColor, ButtonVariant as ButtonComponentVariant } from './Button';
import { getButtonClassNames } from './Button';
import { Icon, type IconProps } from './Icon';

type ButtonVariant =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'legacy-ghost'
  | 'legacy-btn-link'
  | 'legacy-btn-neutral'
  | 'legacy-btn-muted';

type LinkVariant = 'legacy-brand' | 'legacy-neutral' | 'legacy-inherit';
export type Variant = LinkVariant | ButtonVariant;
type LinkButtonSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl';
type LinkShape = 'none' | 'square' | 'circle';

export interface LinkProps extends RouterLinkProps {
  variant?: Variant;
  size?: LinkButtonSize;
  shape?: LinkShape;
  color?: ButtonColor;
  icon?: IconProps['name'];
}

export function Link({
  to,
  variant = 'legacy-brand',
  size = 'md',
  shape = 'none',
  color = 'default',
  icon,
  className,
  children,
  ...rest
}: LinkProps) {
  const isLegacyVariant = variant.startsWith('legacy-');
  const buttonVariant = buttonVariantByLinkVariant[variant];

  return (
    <RouterLink
      to={to}
      className={cx(
        className,
        classesByVariant[variant],
        buttonVariant
          ? getButtonClassNames({ variant: buttonVariant, size, shape, color, isLegacy: isLegacyVariant })
          : [],
      )}
      {...rest}
    >
      {icon ? <Icon name={icon} className={buttonVariant ? '' : 'mr-2'} /> : null}
      {children}
    </RouterLink>
  );
}

const classesByVariant: Record<Variant, string | string[]> = {
  primary: '',
  secondary: '',
  tertiary: '',
  'legacy-brand': 'link link-hover link-primary',
  'legacy-neutral': 'link link-hover link-neutral',
  'legacy-inherit': 'link link-hover',
  'legacy-ghost': '',
  'legacy-btn-link': '',
  'legacy-btn-neutral': '',
  'legacy-btn-muted': '',
};

const buttonVariantByLinkVariant: Partial<Record<Variant, ButtonComponentVariant>> = {
  primary: 'primary',
  secondary: 'secondary',
  tertiary: 'tertiary',
  'legacy-btn-link': 'legacy-link',
  'legacy-btn-neutral': 'legacy-neutral',
  'legacy-btn-muted': 'legacy-muted',
};
