import { useEffect, useState } from 'react';

export interface UTMParams {
  utm_source: string | null;
  utm_medium: string | null;
  utm_campaign: string | null;
  utm_term: string | null;
  utm_content: string | null;
  utm_id: string | null;
}

export function useUTM() {
  const [utmParams, setUtmParams] = useState<UTMParams>({
    utm_source: null,
    utm_medium: null,
    utm_campaign: null,
    utm_term: null,
    utm_content: null,
    utm_id: null,
  });

  useEffect(() => {
    if (!urlHasUTM()) {
      setUtmParams({
        utm_source: localStorage.getItem('utm_source'),
        utm_medium: localStorage.getItem('utm_medium'),
        utm_campaign: localStorage.getItem('utm_campaign'),
        utm_term: localStorage.getItem('utm_term'),
        utm_content: localStorage.getItem('utm_content'),
        utm_id: localStorage.getItem('utm_id'),
      });
      return;
    }

    if (UTMInLocalStorage()) {
      // clean localStorage to ensure we are not mixing UTM from previous visits
      cleanUTMFromLocalStorage();
    }

    const urlParams = new URLSearchParams(window.location.search);

    const utmFromUrl: UTMParams = {
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
      utm_term: null,
      utm_content: null,
      utm_id: null,
    };

    UTM_KEYS.forEach((key) => {
      const value = urlParams.get(key);

      if (value) {
        utmFromUrl[key as UTMKey] = value;
        localStorage.setItem(key, value);
      }
    });

    setUtmParams(utmFromUrl);
  }, []);

  return utmParams;
}

type UTMKey = keyof UTMParams;

const UTM_KEYS: UTMKey[] = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content', 'utm_id'];

function urlHasUTM() {
  const urlParams = new URLSearchParams(window.location.search);

  return UTM_KEYS.some((key) => urlParams.has(key));
}

function UTMInLocalStorage() {
  return UTM_KEYS.some((key) => localStorage.getItem(key));
}

function cleanUTMFromLocalStorage() {
  UTM_KEYS.forEach((key) => localStorage.removeItem(key));
}
