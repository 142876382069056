import { useAtom } from 'jotai';
import { useCallback } from 'react';
import { useNavigate } from 'react-router';

import { useLogger } from '../../logging/hook/useLogger';
import { currentTutorialIdAtom } from '../atoms/currentTutorialIdAtom';
import type { TutorialsIds } from '../constants/tutorials';
import { tutorials } from '../constants/tutorials';
import type { TutorialDefinition } from '../types/tutorial';

export function useTutorial() {
  const [currentTutorialId, setCurrentTutorialId] = useAtom(currentTutorialIdAtom);
  const navigate = useNavigate();
  const { logger } = useLogger();

  const getTutorialById: (id: TutorialsIds) => TutorialDefinition | undefined = useCallback((id: TutorialsIds) => {
    return tutorials.filter((tutorial) => tutorial.id === id)[0];
  }, []);

  const currentTutorial: TutorialDefinition | undefined =
    currentTutorialId != null ? getTutorialById(currentTutorialId) : undefined;

  const start = useCallback(
    ({ tutorialId }: { tutorialId: TutorialsIds }) => {
      const tutorial = getTutorialById(tutorialId);
      logger.info(`Start tutorial ${tutorialId} ${tutorial?.route}`);
      if (tutorial?.route != null) {
        navigate(tutorial.route);
      }
      setCurrentTutorialId(tutorialId);
    },
    [getTutorialById, logger, navigate, setCurrentTutorialId],
  );

  const close = () => setCurrentTutorialId(null);

  return { currentTutorialId, currentTutorial, start, close };
}
