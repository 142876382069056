import cx from 'classnames';

import avatarPlaceholder from '../assets/avatar-placeholder.png';

type AvatarSize = 'xxs' | 'xs' | 'sm' | 'md' | 'lg';

interface AvatarProps extends React.ComponentPropsWithoutRef<'img'> {
  size?: AvatarSize;
  username: string;
}

export function Avatar({ className, username, src, size = 'md', ...rest }: AvatarProps) {
  return (
    <div className={cx('avatar', className, { placeholder: !src })}>
      {src ? (
        <div className={cx('bg-neutral-focus text-neutral-content rounded-full', classesBySize[size])}>
          <img src={src} alt={`${username}'s avatar`} {...rest} loading="lazy" />
        </div>
      ) : (
        <div className={cx('bg-coldgray-50 text-neutral-content rounded-full', classesBySize[size])}>
          <span className={fontClassesBySize[size]} aria-hidden="true">
            <img src={avatarPlaceholder} alt={`${username}'s avatar`} {...rest} loading="lazy" />
          </span>
        </div>
      )}
    </div>
  );
}

const classesBySize: Record<AvatarSize, string> = {
  xxs: 'w-5',
  xs: 'w-6',
  sm: 'w-12',
  md: 'w-20',
  lg: 'w-32',
};

const fontClassesBySize: Record<AvatarSize, string> = {
  xxs: 'text-xxs',
  xs: 'text-xs',
  sm: '',
  md: 'text-xl',
  lg: 'text-3xl',
};
