import { useMutation } from '@tanstack/react-query';

import type { ApiError } from '../../../api/ApiError';
import { useAuth } from '../../../features/auth/useAuth';
import type { Flag } from '../types/Flag';

export type UpdateFlagFn = (props: { accessToken?: string; id: string; flag: Flag }) => Promise<object>;

export interface UseFlagProps {
  onSuccess?: () => void;
  onError?: (apiError: ApiError) => void;
  updateFlagFn: UpdateFlagFn;
}

export function useFlag({ onSuccess, onError, updateFlagFn }: UseFlagProps) {
  const { withAuth } = useAuth();
  const { mutate, ...rest } = useMutation(withAuth(updateFlagFn), {
    onSuccess,
    onError,
  });
  return { ...rest, mutate: mutate };
}
