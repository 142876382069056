import type { ApiError } from '../../../api/ApiError';

type HandleErrorNotification = (notify: (text: React.ReactNode) => void) => ({ name, status, code }: ApiError) => void;

export const handleErrorNotification: HandleErrorNotification = (notify) => (error: ApiError) => {
  switch (error.code) {
    case 'invalid_event':
      notify('You cannot perform this action on the current collaboration state.');
      break;
    case 'has_remaining_client_content_feedbacks':
      notify('You cannot file a content claim when you still have feedbacks available.');
      break;
    case 'no_promo_code_available_to_assign':
      notify(
        `Your campaign has no promo code to assign to the creator. You cannot accept new creators until you provide more promo codes.`,
      );
      break;

    default:
      if (error.message) {
        notify(error.message);
      } else {
        notify(`Unknown error - ${error.code}`);
      }
      break;
  }
};
