import { QueryClient, QueryClientProvider as RQQueryClientProvider } from '@tanstack/react-query';

import { ApiError } from '../api/ApiError';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // Prevent painful refetch when mocks are enabled
      refetchOnWindowFocus: import.meta.env.VITE_ENABLE_API_MOCKS === 'true',
      // Prevent refetching too often, e.g. between redirections
      // Also, with SSR, we usually want to set some default staleTime
      // above 0 to avoid refetching immediately on the client
      staleTime: 5 * 1000, // 5 seconds
      retry(failureCount, error) {
        // Prevent retry on client side errors
        if (error && error instanceof ApiError && error.status < 500) {
          return false;
        }
        return failureCount < 3;
      },
    },
  },
});

export function QueryClientProvider({ children }: { children: React.ReactNode; refetchOnWindowFocus?: boolean }) {
  return <RQQueryClientProvider client={queryClient}>{children}</RQQueryClientProvider>;
}
