import type { Placement } from '@floating-ui/react';
import cx from 'classnames';
import { Link } from 'react-router';

import { Icon } from '~/components/Icon';
import { Tooltip, TooltipContent, TooltipTrigger } from '~/components/tooltip/Tooltip';

interface QuestionMarkWithTooltipProps {
  tooltipContent: string;
  placement?: Placement;
  className?: string;
  trigger?: 'hover' | 'click';
  link?: string;
}

export function QuestionMarkWithTooltip({
  tooltipContent,
  placement = 'top',
  className,
  trigger = 'hover',
  link,
}: QuestionMarkWithTooltipProps) {
  return (
    <Tooltip placement={placement} trigger={trigger}>
      <TooltipTrigger>
        <Icon
          name="QuestionMark"
          className={cx('mb-1 rounded-full text-m-regular !text-warmgray-300 hocus:!text-inactive/50', className)}
        />
      </TooltipTrigger>
      <TooltipContent>
        {tooltipContent}
        {link ? (
          <span>
            {' '}
            <Link
              to={link}
              className="whitespace-nowrap text-xs-semibold text-blue-500 hocus:text-blue-400"
              target="_blank"
              rel="noreferrer"
            >
              Learn More <Icon name="CaretRight" className="-ml-1" />
            </Link>
          </span>
        ) : null}
      </TooltipContent>
    </Tooltip>
  );
}
