import * as Sentry from '@sentry/core';
import type { Mechanism } from '@sentry/types';
import { isRouteErrorResponse } from 'react-router';
import type { Primitive } from 'utility-types';

interface AdditionalContext {
  tags?: Record<string, Primitive>;
  extra?: Record<string, Primitive | Primitive[]>;
}

interface EventHint {
  mechanism: Pick<Mechanism, 'type' | 'handled' | 'data'>;
}

export function captureException(exception: unknown, context?: AdditionalContext | EventHint) {
  return Sentry.captureException(exception, context);
}

export function setUser(user: { id: string } | null) {
  Sentry.setUser(user ? { id: user.id } : null);
}

/** Mimicks https://github.com/getsentry/sentry-javascript/blob/7.120.3/packages/remix/src/utils/instrumentServer.ts */
export function captureReactRouterServerException(err: unknown) {
  // Skip capturing if the thrown error is not a 5xx response
  if (isRouteErrorResponse(err) && err.status < 500) {
    return;
  }

  captureException(err, {
    mechanism: {
      type: 'instrument',
      handled: false,
      data: {
        function: 'reactRouter.server.handleError',
      },
    },
  });
}

/** Mimicks https://github.com/getsentry/sentry-javascript/blob/7.120.3/packages/remix/src/client/errors.tsx */
export function captureReactRouterErrorBoundaryError(error: unknown): string | undefined {
  if (isResponse(error) || typeof document === 'undefined' || !(error instanceof Error)) {
    return;
  }

  return captureException(error, {
    mechanism: {
      type: 'instrument',
      handled: false,
      data: {
        function: 'ReactError',
      },
    },
  });
}

// Copied Sentry internal
function isResponse(value: any): value is Response {
  return (
    value != null &&
    typeof value.status === 'number' &&
    typeof value.statusText === 'string' &&
    typeof value.headers === 'object' &&
    typeof value.body !== 'undefined'
  );
}
