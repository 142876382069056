import { fetchAPI } from '../../../api/fetch';

import type { CollaborationFlag } from './types';

interface FlagCollaborationProps {
  accessToken?: string;
  id: string;
  flag: CollaborationFlag;
}

export type FlagCollaborationResponseBody = Record<string, never>;

export async function flagCollaboration({
  accessToken,
  id,
  flag,
}: FlagCollaborationProps): Promise<FlagCollaborationResponseBody> {
  await fetchAPI(`/client/collaborations/${id}/flag`, {
    accessToken,
    method: 'PUT',
    body: JSON.stringify({ flag }),
    headers: { 'Content-Type': 'application/json' },
  });
  return {};
}
